import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ImageModule } from '@app/shared/components/image/image.module';
import { SharedModule } from '@app/shared/shared.module';
import { SimilaritySearchDialogModule } from '@app/tracks/shared/similarity-search-dialog/similarity-search-dialog.module';
import { SearchBarResultsItemComponent } from './search-bar-results-item.component';

@NgModule({
  imports: [
    SharedModule,
    ImageModule,
    MatTooltipModule,
    MatDialogModule,
    SimilaritySearchDialogModule
  ],
  declarations: [SearchBarResultsItemComponent],
  exports: [SearchBarResultsItemComponent]
})
export class SearchBarResultsItemModule { }
