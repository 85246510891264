import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { SpotifyTrack } from '@app/shared/models/classes/SpotifyTrack';
import { debounceTime, distinctUntilChanged, fromEvent, map, shareReplay } from 'rxjs';

@Component({
    selector: 'sound-search-bar-results',
    templateUrl: './search-bar-results.component.html',
    styleUrls: ['./search-bar-results.component.scss'],
    standalone: false
})
export class SearchBarResultsComponent extends BaseComponent implements OnInit {

  /// -----------------------------------------------------------------------------------------------------
  // @ VIEW CHILD/CHILDREN VARIABLES
  // ------------------------------------------------------------------------------------------------------

  @ViewChild('searchBarResults', { static: false }) searchBarResults: ElementRef;

  /// -----------------------------------------------------------------------------------------------------
  // @ OUTPUT VARIABLES
  // ------------------------------------------------------------------------------------------------------

  @Output() hasSearched: EventEmitter<boolean> = new EventEmitter<boolean>();


  @Output() scrolledToBottom: EventEmitter<boolean> = new EventEmitter<boolean>();

  /// -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // ------------------------------------------------------------------------------------------------------

  private _isVisible: boolean = false;
  @Input()
  set isVisible(isVisible: boolean) {
    this._isVisible = isVisible;
  }
  get isVisible(): boolean {
    return this._isVisible;
  }

  private _items: SpotifyTrack[] = [];
  @Input()
  set items(items: SpotifyTrack[]) {
    if (!this.IsNullOrUndefined(items)) {
      this._items = items;
      this.isVisible = true;
    }
  }
  get items(): SpotifyTrack[] {
    return this._items;
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // ------------------------------------------------------------------------------------------------------

  constructor() {
    super();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // ------------------------------------------------------------------------------------------------------

  onScroll(event: Event): void {
    const element: HTMLElement = event.target as HTMLElement;
    const scrollHeight: number = element.scrollHeight;
    const scrollTop: number = element.scrollTop;
  
    if ((scrollTop + 400) / scrollHeight > 0.85) {
      this.scrolledToBottom.emit(true);
    }
  }

  @HostListener('document:click', ['$event']) onDocumentClick($event: any) {
    if (this.isVisible === true) {
      const path = $event.path || $event.composedPath();
      let index: number;
      index = path.findIndex((q: Element) => q.firstElementChild === this.searchBarResults.nativeElement.firstElementChild);
      if (index === -1) {
        this.isVisible = false;
      }
    }
  }

}
