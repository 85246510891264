import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { SearchBarDropdownComponent } from './search-bar-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule
  ],
  declarations: [SearchBarDropdownComponent],
  exports: [SearchBarDropdownComponent]
})
export class SearchBarDropdownModule { }
