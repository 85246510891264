import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '@app/shared/base/components/base-component';

@Component({
    selector: 'sound-search-bar-dropdown',
    templateUrl: './search-bar-dropdown.component.html',
    styleUrls: ['./search-bar-dropdown.component.scss'],
    standalone: false
})
export class SearchBarDropdownComponent extends BaseComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  isExpanded: boolean = false;

  selectedType: string = '';

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() form: UntypedFormGroup;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor() {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE METHODS
  // -----------------------------------------------------------------------------------------------------

  setValue(option: any): void {
    this.form.get('selected').setValue(option);
    this.isExpanded = false;
  }
  
}
