import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { I18nService } from '@app/core/i18n.service';
import { AppRoute } from '@app/shared/app.route.enum';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { SpotifyArtist, SpotifyTrack } from '@app/shared/models/classes/SpotifyTrack';
import { MusicPlayerService } from '@app/shared/services/local/music-player.service';
import { SimilaritySearchService } from '@app/shared/services/local/similarity-search.service';
import { SimilaritySearchRoute } from '@app/similarity-search/shared/similarity-search.route.enum';
import { SimilaritySearchDialogComponent, SimilaritySearchDialogData } from '@app/tracks/shared/similarity-search-dialog/similarity-search-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'sound-search-bar-results-item',
    templateUrl: './search-bar-results-item.component.html',
    styleUrls: ['./search-bar-results-item.component.scss'],
    standalone: false
})
export class SearchBarResultsItemComponent extends BaseComponent implements OnInit {

  /// -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // ------------------------------------------------------------------------------------------------------

  private _item: SpotifyTrack;
  @Input()
  set item(item : SpotifyTrack) {
    this._item = item;
  }
  get item() : SpotifyTrack {
    return this._item;
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ OUTPUT VARIABLES
  // ------------------------------------------------------------------------------------------------------

  @Output() hasSearched: EventEmitter<boolean> = new EventEmitter<boolean>();

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // ------------------------------------------------------------------------------------------------------

  constructor(
    private router: Router,
    private similaritySearchService: SimilaritySearchService,
    private dialog: MatDialog,
    private i18nService: I18nService,
    private musicService: MusicPlayerService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    super();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
  }
  
  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // ------------------------------------------------------------------------------------------------------

  navigateToSimilaritySearch(): void {
    this.similaritySearchService.spotifyTrack = this.item;

    super.addSubscription(
      this.dialog.open(SimilaritySearchDialogComponent, {
        data: Object.assign(new SimilaritySearchDialogData(), {
          spotifyId: this.item.id,
          type: 'Spotify'
        }),
        maxWidth: '58.571rem'
      }).afterClosed()
        .subscribe({
          next: (id: string) => {
            if (!this.IsNullOrUndefinedOrEmpty(id)) {
              this.musicService.pause(undefined, true);
              this.musicService.pause(undefined);
              
              this.router.navigate([this.i18nService.getAbsoluteUrlWithCurrentLanguage(`${AppRoute.SimilaritySearch}/${SimilaritySearchRoute.Spotify}`), this.item.id]);
              this.hasSearched.emit(false);
            } else {
              this.toastr.error(this.translateService.instant('General.An error that should not happened has happened. Sorry for that. Please contact the administrator'));
            }
          }
        })
    );
    
   
  }

  getSpotifyArtistsNames(artists: SpotifyArtist[]): string {
    return artists?.map(a => a.name).join(', ') ?? '';
  }
}
