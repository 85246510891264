import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { I18nService } from '@app/core/i18n.service';
import { AppRoute } from '@app/shared/app.route.enum';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { SpotifyTrack } from '@app/shared/models/classes/SpotifyTrack';
import { TaskStatus, WebhookTask } from '@app/shared/models/classes/WebhookTask';
import { SearchBy } from '@app/shared/models/enums/SearchBys';
import { MusicPlayerService } from '@app/shared/services/local/music-player.service';
import { TasksService } from '@app/shared/services/tasks.service';
import { TracksService } from '@app/shared/services/tracks.service';
import { SimilaritySearchRoute } from '@app/similarity-search/shared/similarity-search.route.enum';
import {
  SimilaritySearchDialogComponent,
  SimilaritySearchDialogData,
} from '@app/tracks/shared/similarity-search-dialog/similarity-search-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { concatMap, debounceTime, delay, finalize, Observable, of } from 'rxjs';

import { FileContainer } from '../../files/models/FileContainer';

@Component({
    selector: 'sound-search-bar-search',
    templateUrl: './search-bar-search.component.html',
    styleUrls: ['./search-bar-search.component.scss'],
    standalone: false
})
export class SearchBarSearchComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  SearchBy = SearchBy;

  items: SpotifyTrack[] = [];

  hasSearched: boolean = false;

  mailtoContact: string;

  mp3ErrorMessage: string = '';

  totalSpotifySearch: number;

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() form: UntypedFormGroup;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private router: Router,
    private tracksService: TracksService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private musicService: MusicPlayerService,
    private i18nService: I18nService,
    private tasksService: TasksService,
    private toastr: ToastrService,
  ) {
    super();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // ------------------------------------------------------------------------------------------------------

  ngOnInit(): void {
      this.form.get('searchText')
        .valueChanges
        .pipe(
          debounceTime(500)
        )
        .subscribe(() => {
          if (this.form.get('selected').value?.value === SearchBy.Spotify) {
            this.triggerSearch();
          }
        })
    
    this.mailtoContact = `mailto:info@soundtaxi.com?subject=Please%20find%20the%20right%20song%20for%20me&body=Dear%20Soundtaxi%20team%2C%0D%0A%0D%0AI%20require%20a%20specific%20song%2C%20please%20help%20me%20in%20my%20search.%0D%0A%0D%0AThe%20song%20should%20%3CEnter%20song%20description%3E.%0D%0A%0D%0AMy%20contact%20information%3A%0D%0A%0D%0A%3CEnter%20contact%20information%3E%0D%0A%0D%0ABest%20regards%0D%0A%0D%0A%3CYour%20name%3E`;
  }

  asAny(value: any): any {
    return value;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  loadMore(): void {
    // Spotify search 
    const skip: number = this.items.length - (this.items.length % 20);

    const take: number = 20;

    const maxSkip: number = 2000;

    if (this.isLoading === false && this.items.length % 20 === 0 && skip >= take && skip <= maxSkip) {
      this.isLoading = true;

      const spotifySearch = this.form.get('searchText').value;

      super.addSubscription(
        this.tracksService
          .getTracksBySpotifySearch(spotifySearch, take, skip)
          .pipe(
            finalize(() => this.isLoading = false)
          )
          .subscribe({
            next: (data: any) => {
              this.items = this.items.concat(data);
              // this.totalSpotifySearch = data?.total;
            }
          })
      );
    }
  }

  openSimilaritySearchDialog(files: FileContainer[]): void {
    this.mp3ErrorMessage = '';

    super.addSubscription(
      this.dialog.open(SimilaritySearchDialogComponent, {
        data: Object.assign(new SimilaritySearchDialogData(), {
          mp3: files[0],
          type: 'Mp3'
        }),
        maxWidth: '58.571rem'
      }).afterClosed()
        .subscribe({
          next: (cyaniteId: string) => {
            if (!this.IsNullOrUndefinedOrEmpty(cyaniteId)) {
              this.musicService.pause(undefined, true);
              this.musicService.pause(undefined);
              
              this.router.navigate([this.i18nService.getAbsoluteUrlWithCurrentLanguage(`${AppRoute.SimilaritySearch}/${SimilaritySearchRoute.MP3}`), cyaniteId]);
            } else if (cyaniteId === null ) {
              this.mp3ErrorMessage = this.translateService.instant('Search.The file could not be processed due to an error with cyanite');
            }
          }
        })
    );
  }

  setErrors(event: any): void {
    if (!this.IsNullOrUndefined(event)) {
      this.mp3ErrorMessage = `(${this.translateService.instant('Search.invalid file format')})`;
    }
  }

  triggerSearch(value?: string): void {
    value = value ?? this.form.get('searchText').value;
    switch (this.form.get('selected').value?.value) {
      case SearchBy.FreeTextSearch:
        const freeTextsearch = value;
        
        if (!this.IsNullOrUndefinedOrEmpty(freeTextsearch)) {
          this.router.navigate([this.i18nService.getAbsoluteUrlWithCurrentLanguage(`${AppRoute.SimilaritySearch}/${SimilaritySearchRoute.FreeTextSearch}`), freeTextsearch]);
        }
        break;
      case SearchBy.Search:
        const searchValue = value;

        if (!this.IsNullOrUndefinedOrEmpty(searchValue)) {
          this.router.navigateByUrl(this.i18nService.getAbsoluteUrlWithCurrentLanguage(`${AppRoute.Browse}?search=${searchValue}`))
        }
        break;
      case SearchBy.Spotify:
        const spotifySearch = value;

        if (!this.IsNullOrUndefinedOrEmpty(spotifySearch)) {
          // Load results
          this.isLoading = true;
          super.addSubscription(
            this.tracksService
              .getTracksBySpotifySearch(spotifySearch)
              .pipe(
                finalize(() => this.isLoading = false)
              )
              .subscribe({
                next: (data: any) => {
                  this.items = data.fullTracks;
                  // this.totalSpotifySearch = data?.total;
                  this.hasSearched = true;
                },
                error: (error: HttpErrorResponse) => {
                  switch (error.error.code.internalCode) {
                      case 1109:
                        this.toastr.error(this.translateService.instant('Search.Something went wront communitcate  with cyanite, please cantact an administrator'));
                        break;
                      case 1110:
                        this.toastr.error(this.translateService.instant('Search.Something went wront communitcate  with spotify, please cantact an administrator'));
                        break;
                      default:
                        this.toastr.error(this.translateService.instant('Search.General.An error that should not happened has happened. Sorry for that. Please contact the administrator'));
                        break;
                    }
              }
              })
          );
        }
        break;
      default:
        break;
    }
  }

  closeSearchResult(value: boolean): void {
    this.form.patchValue({
      searchText: null
    });
    this.hasSearched = value;
  }

  private pollTaskById(taskIds: string[], startTime: Moment, tracks: SpotifyTrack[]): Observable<any> {
    if (moment().isBefore(startTime.add(3, 'minutes'))) {
      return this.tasksService
        .getTasks(taskIds)
        .pipe(
          delay(2000),
          concatMap((tasks: WebhookTask[]) => {
            if (tasks.some(q => q.status !== TaskStatus.Completed && q.status !== TaskStatus.Erroneous)) {
              return this.pollTaskById(taskIds, startTime, tracks);
            }
            // if (task.status === TaskStatus.Completed) {
            //   return of(task);
            // }
            // if (task.status === TaskStatus.Erroneous) {
            //   console.log('ERROR');
            //   // TODO: Display error message
            //   return of(task);
            // }

            return of(tracks);
          })
        );
    }

  }
}
