import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'sound-search-bar-search-input',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  templateUrl: './search-bar-search-input.component.html',
  styles: [
  ]
})
export class SearchBarSearchInputComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() form: UntypedFormGroup;

  // -----------------------------------------------------------------------------------------------------
  // @ OUTPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Output() searchTextChanged: EventEmitter<string> = new EventEmitter<string>();

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
  ) {
      super();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // ------------------------------------------------------------------------------------------------------

  ngOnInit(): void {
      this.form.get('searchText')
          .valueChanges
          .pipe(
              debounceTime(500)
          )
          .subscribe(value => {
              this.searchTextChanged.emit(value);
          })
  }

  asAny(value: any): any {
      return value;
  }
}