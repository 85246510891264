<!-- RESULT ITEM -->
<div class="search-result-item__track flex-initial flex flex-row items-center" (click)="navigateToSimilaritySearch()">
  <!-- IMAGE -->
  <sound-image [src]="item?.album.images[0]?.url" [height]="'5.714rem'" [width]="'5.714rem'"></sound-image>
  <!-- META -->
  <div class="mx-4 overflow-hidden">
    <!-- TITLE -->
    <div class="search-result-item__track__name" [matTooltipPosition]="'above'" [matTooltip]="item?.name">
      {{ item?.name }}</div>
    <!-- ARTISTS -->
    <div class="search-result-item__track__subtitle">
      {{ getSpotifyArtistsNames(item?.artists) }}
    </div>
  </div>
</div>