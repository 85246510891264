<div [ngClass]="{'search-bar-dropdown': true, 'search-bar-dropdown--expanded': isExpanded}" (click)="isExpanded = !isExpanded">
  <div>
    {{ 'Search.Search by' | translate }}<span class="search-type"> {{ form?.get('selected').value?.displayName | translate }}</span>
  </div>
  <mat-icon [svgIcon]="'chevron_right'" [ngClass]="{'drop-down': isExpanded, 'expander': true}"></mat-icon>
</div>

<div [ngClass]="{'search-bar-dropdown__options': true, 'hidden': isExpanded === false, 'visible': isExpanded === true}">
  <div
    [ngClass]="{'search-bar-dropdown__option': true, 'search-bar-dropdown__option--selected': form?.get('selected').value?.value === option.value}"
    *ngFor="let option of form?.get('values').value" (click)="setValue(option)">
    {{ option.displayName | translate }}
  </div>
</div>