import { __ } from '@app/shared/functions/object.functions';
import { SearchBy } from '../enums/SearchBys';

export class SearchBysHelper {
    static SearchBys: any[] = [
        {
            displayName: 'Search.Spotify',
            value: SearchBy.Spotify,
            icon: 'search',
            searchText: 'Search.Share a link for similarity search…'
        },
        {
            displayName: 'Search.Free text search',
            value: SearchBy.FreeTextSearch,
            icon: 'search',
            searchText: 'Search.Free text like relaxing and quiet song for meditation'
        },
        {
            displayName: 'Search.MP3',
            value: SearchBy.MP3,
            icon: 'upload',
            searchText: 'Search.Drag & drop your Mp3 here'
        },
        {
            displayName: 'Search.Search',
            value: SearchBy.Search,
            icon: 'search',
            searchText: 'General.Search for'
        },
        {
            displayName: 'Search.Soundtaxi',
            value: SearchBy.Soundtaxi,
            icon: 'soundtaxi_logo',
            searchText: 'Search.Show us your project or tell us more about it. We will take it from there!'
        }
    ];

    static GetSearchBysByNameOrNull(displayName: string): any {
        const result = SearchBysHelper.SearchBys.find((range: any) => range.displayName === displayName);

        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }

        return result;
    }

    static GetSearchBysByValueOrNull(value: string): any {
        const result = SearchBysHelper.SearchBys.find((range: any) => range.value === value);

        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }

        return result;
    }
}
