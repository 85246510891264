import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarResultsComponent } from './search-bar-results.component';
import { SharedModule } from '@app/shared/shared.module';
import { SearchBarResultsItemModule } from './item/search-bar-results-item.module';

@NgModule({
  imports: [
    SharedModule,
    SearchBarResultsItemModule
  ],
  declarations: [SearchBarResultsComponent],
  exports: [SearchBarResultsComponent]
})
export class SearchBarResultsModule { }
