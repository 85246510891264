<!-- RESULTS -->
<ng-container *ngIf="isVisible === true;">
  <div #searchBarResults class="search-result-items max-h-120 flex flex-col p-2" (scroll)="onScroll($event)">

    <!-- ITEMS -->
    <ng-container *ngIf="items?.length > 0; else emptyMessage">
      <ng-container *ngFor="let item of items;">
        <sound-search-bar-results-item (hasSearched)="this.hasSearched.emit($event)" [item]="item"></sound-search-bar-results-item>
      </ng-container>
    </ng-container>

    <!-- EMPTY MESSAGE -->
    <ng-template #emptyMessage>
      <div class="flex flex-col items-center justify-center">
        <div class="text-center">
          <p class="text-secondary-gray-20">
            {{ 'Search.No results found for this search' | translate }}
          </p>
        </div>
      </div>
    </ng-template>
    
  </div>
</ng-container>