export class SpotifyTrack {
    album: SpotifyAlbum;
    artists: SpotifyArtist[];
    availableMarkets: string[];
    discNumber: number;
    durationMs: number;
    explicit: boolean;
    externalIds: any;
    externalUrls: any;
    href: string;
    id: string;
    isLocal: boolean;
    isPlayable: boolean;
    name: string;
    popularity: number;
    previewUrl: string;
    trackNumber: number;
    type: string;
    uri: string;
}

export class SpotifyAlbum {
    albumType: string;
    artists: SpotifyArtist[];
    availableMarkets: string[];
    externalUrls: any;
    href: string;
    id: string;
    images: SpotifyImage[];
    name: string;
    releaseDate: string;
    releaseDatePrecision: string;
    totalTracks: number;
    type: string;
    uri: string;
}

export class SpotifyArtist {
    externalUrls: any;
    href: string;
    id: string;
    name: string;
    type: string;
    uri: string;
}

export class SpotifyImage {
    height: number;
    url: string;
    width: number;
} 