import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SimilaritySearchDialogModule } from '@app/tracks/shared/similarity-search-dialog/similarity-search-dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsDefaultModule } from '../../buttons/default/buttons-default.module';
import { FileDropzoneModule } from '../../files/file-dropzone/file-dropzone.module';
import { SearchBarResultsModule } from '../results/search-bar-results.module';
import { SearchBarSearchComponent } from './search-bar-search.component';
import { SearchBarSearchInputComponent } from '../input/search-bar-search-input.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    FileDropzoneModule,
    ButtonsDefaultModule,
    ReactiveFormsModule,
    SearchBarResultsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    SimilaritySearchDialogModule,

    SearchBarSearchInputComponent
  ],
  declarations: [SearchBarSearchComponent],
  exports: [SearchBarSearchComponent],
  // providers: [TracksService]
})
export class SearchBarSearchModule { }
