import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { SearchBarComponent } from './search-bar.component';
import { SearchBarDropdownModule } from './dropdown/search-bar-dropdown.module';
import { SearchBarSearchModule } from './search/search-bar-search.module';
import { SearchBarResultsModule } from './results/search-bar-results.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    SearchBarDropdownModule,
    SearchBarSearchModule
  ],
  declarations: [SearchBarComponent],
  exports: [SearchBarComponent]
})
export class SearchBarModule { }
